import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getStockSubtraction, getNext, handleSearch, createStockSubtraction } from "./thunk";

const initialState = {
  stockSubtractions: [],
  count: null,
  next: null,
  previous: null,
  loadingStockSubtraction: false,
  loading: false,
  loadingUpdated: false,
  loadingNext: false,
};

export const stockSubtractionSlice = createSlice({
  name: "stockSubtraction",
  initialState,
  reducers: {
    clearAllStockSubtraction: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.stockSubtractions = [...state.stockSubtractions, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createStockSubtraction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createStockSubtraction.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createStockSubtraction.rejected, (state) => {
      state.loading = false;
    });
    builder.addMatcher(isAnyOf(getStockSubtraction.pending, handleSearch.pending), (state) => {
      state.loadingStockSubtraction = true;
    });
    builder.addMatcher(isAnyOf(getStockSubtraction.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingStockSubtraction = false;
      state.stockSubtractions = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getStockSubtraction.rejected, handleSearch.rejected), (state) => {
      state.loadingStockSubtraction = false;
    });
  },
});

export const { clearAllStockSubtraction } = stockSubtractionSlice.actions;

export default stockSubtractionSlice.reducer;
