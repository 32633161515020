import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get stockAnalysisByBatch
export const getStockAnalysisByBatch = createAsyncThunk(
  "stockAnalysisByBatch/getStockAnalysisByBatch",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getStockAnalysisByBatch(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all stockAnalysisByBatch
export const getAllStockAnalysisByBatch = createAsyncThunk(
  "stockAnalysisByBatch/getAllStockAnalysisByBatch",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllStockAnalysisByBatch();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("stockAnalysisByBatch/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("stockAnalysisByBatch/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageStockAnalysisByBatch = createAsyncThunk(
  "stockAnalysisByBatch/getPageStockAnalysisByBatch",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageStockAnalysisByBatch(number, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk("stockAnalysisByBatch/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
