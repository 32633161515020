import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getLocation = (postsPerPage) =>
  axiosInstance.get(
    `api/v1/warehouse-location-app/location?offset=0&limit=${postsPerPage}&ordering=id`
  );

//obtaining all data
export const getAllLocation = (departmentId) =>
  axiosInstance.get(
    `api/v1/warehouse-location-app/location?department=${departmentId}&limit=0&ordering=id`
  );

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageLocation = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/warehouse-location-app/location?offset=${
      (number - 1) * postsPerPage
    }&limit=${postsPerPage}&ordering=id`
  );

//creating function
export const createLocation = (body) =>
  axiosInstance.post(`api/v1/warehouse-location-app/location`, body);


//update function
export const updateLocation = (id, body) =>
  axiosInstance.patch(`api/v1/warehouse-location-app/location/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(
    `api/v1/warehouse-location-app/location?offset=0&limit=${postsPerPage}&search=${search}`
  );

// checking the redundant data
export const checkRedundantData = (name, cancelToken, parent) =>
  axiosInstance.get(
    `api/v1/warehouse-location-app/location?name=${name.trim()}&parent=${parent}`,
    {
      cancelToken: cancelToken.token,
    }
  );
export const getInfoOfPackCode = (code) =>
  axiosInstance.get(`api/v1/item-serialization-app/pack-code-location/${code}`);
  
export const locationShift = (body) =>
  axiosInstance.post(
    `api/v1/item-serialization-app/update-pack-location`,
    body
  );
