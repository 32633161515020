import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";
import getCookie from "../../Utils/Cookies/getCookie";
import { errorFunction, successFunction } from "../../Components/Alert/Alert";

// login
export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    const { userName, password, subDomain } = credentials;
    try {
      const body = { userName, password, subDomain };
      const { data } = await API.login(body);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.detail);
    }
  }
);

// logout
export const logout = createAsyncThunk(
  "auth/logout",
  async (token, { rejectWithValue }) => {
    try {
      const body = JSON.stringify({ refresh: token });
      await API.logout(body);
      return;
    } catch (error) {
      return rejectWithValue(error.response.data.detail);
    }
  }
);

// reset password
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (email, { rejectWithValue }) => {
    try {
      const body = JSON.stringify({ email: email });
      const { data } = await API.resetPassword(body);
      successFunction(
        "Check your email and click on the link to reset your Password."
      );
      return data;
    } catch (error) {
      errorFunction(
        `There is no active user associated with this e-mail address. Password can not be changed `
      );
      return rejectWithValue("Failed to reset password.");
    }
  }
);

// confirm password
export const confirmPassword = createAsyncThunk(
  "auth/confirmPassword",
  async (value, { rejectWithValue }) => {
    const { password, token, history } = value;
    try {
      const body = JSON.stringify({ password, token });
      const { data } = await API.confirmPassword(body);
      history.push("/");
      return data;
    } catch (error) {
      return rejectWithValue("Password confirmation failed.");
    }
  }
);

// change password
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (values, { rejectWithValue, dispatch }) => {
    const { id, password, oldPassword, confirmPassword, history } = values;
    try {
      const token = getCookie("refreshToken");
      const body = JSON.stringify({ password, oldPassword, confirmPassword });

      const { data } = await API.changePassword(id, body);

      if (data) {
        dispatch(logout(token));
        history.push("/");

        return;
      }
      // history.push("/");
      // return data;
    } catch (error) {
      return rejectWithValue("Failed to change password.");
    }
  }
);

// get all branches
export const getAllBranches = createAsyncThunk(
  "auth/getAllBranches",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllBranches();
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get branches.");
    }
  }
);
// check setup complete
export const checkSetup = createAsyncThunk(
  "auth/checkSetup",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.checkSetup();
      return data;
    } catch (error) {
      return rejectWithValue("Failed to complete setup.");
    }
  }
);