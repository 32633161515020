import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { getPickList, getNext, handleSearch, createPickList, updatePickList } from "./thunk";

const initialState = {
  pickLists: [],
  pickList: null,
  pickDetails: [],
  count: null,
  next: null,
  edit: false,
  loading: false,
  loadingUpdated: false,
  loadingPickList: false,
  loadingNext: false,
};

export const pickListSlice = createSlice({
  name: "pickList",
  initialState,
  reducers: {
    pickListEditSuccess: (state, action) => {
      const pickList = state.pickLists?.find((list) => list.id === action.payload);
      state.edit = true;
      state.pickList = pickList;
      state.pickDetails = pickList?.pickDetails.map((detail) => ({ ...detail, unique: uuidv4() }));
    },
    pickDetailsAdded: (state, action) => {
      state.pickDetails = action.payload;
    },
    pickDetailsUpdated: (state, action) => {
      state.pickDetails = action.payload;
    },
    pickDetailsDeleted: (state, action) => {
      state.pickDetails = state.pickDetails.filter((detail) => detail.unique !== action.payload);
    },
    clearAllPickLists: (state) => {
      state.loading = false;
      state.pickDetails = [];
      state.edit = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.pickLists = [...state.pickLists, ...action.payload.results];
      state.count = action.payload.count;
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createPickList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPickList.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createPickList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePickList.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updatePickList.fulfilled, (state) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updatePickList.rejected, (state) => {
      state.loadingUpdated = false;
    });

    builder.addMatcher(isAnyOf(getPickList.pending, handleSearch.pending), (state) => {
      state.loadingPickList = true;
    });
    builder.addMatcher(isAnyOf(getPickList.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingPickList = false;
      state.edit = false;
      state.pickLists = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getPickList.rejected, handleSearch.rejected), (state) => {
      state.loadingPickList = false;
      state.edit = false;
    });
  },
});

export const { pickListEditSuccess, pickDetailsAdded, pickDetailsDeleted, pickDetailsUpdated, clearAllPickLists } =
  pickListSlice.actions;

export default pickListSlice.reducer;
