import axiosInstance from "../../../Utils/axios";

// API for notification
export const getAllNotifications = (postsPerPage) =>
  axiosInstance.get(`/api/v1/notification-app/user-notification?ordering=-id&limit=${postsPerPage}`);

export const getNotification = (id) => axiosInstance.get(`api/v1/notification-app/user-notification/${id}/`);
export const getNotificationCount = () => axiosInstance.get(`api/v1/notification-app/user-notification/count`);

export const updateNotification = (id, body) =>
  axiosInstance.patch(`api/v1/notification-app/user-notification/${id}/`, body);

export const markAllNotificationRead = () => axiosInstance.post(`api/v1/notification-app/user-notification/read-all`);
