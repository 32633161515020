import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get department
export const getDepartment = createAsyncThunk("department/getDepartment", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getDepartment(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get departments.");
  }
});

// get all departments
export const getAllDepartment = createAsyncThunk("department/getAllDepartment", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllDepartment();
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get departments.");
  }
});

// get previous
export const getPrevious = createAsyncThunk("department/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get departments.");
  }
});
// get next
export const getNext = createAsyncThunk("department/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get departments.");
  }
});

// get particular page
export const getParticularDepartment = createAsyncThunk(
  "department/getParticularDepartment",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getParticularDepartment(number, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue("Failed to get departments.");
    }
  }
);

// create department
export const createDepartment = createAsyncThunk(
  "department/createDepartment",
  async (createData, { rejectWithValue, dispatch }) => {
    const { name, code, allowSales, allowPurchase, active } = createData;
    try {
      const body = JSON.stringify({
        name,
        code,
        allowSales,
        allowPurchase,
        active,
      });
      const { data } = await API.createDepartment(body);
      dispatch(getDepartment(20));
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create department.");
    }
  }
);
// update department
export const updateDepartment = createAsyncThunk(
  "department/updateDepartment",
  async (updateData, { rejectWithValue, dispatch }) => {
    const { id, values } = updateData;
    const { name, code, allowSales, allowPurchase, active, remarks } = values;
    try {
      const body = JSON.stringify({
        name,
        code,
        allowSales,
        allowPurchase,
        active,
        remarks,
      });
      const { data } = await API.updateDepartment(id, body);
      dispatch(getDepartment(20));
      return data;
    } catch (error) {
      return rejectWithValue("Failed to update department.");
    }
  }
);

// delete department
export const deleteDepartment = createAsyncThunk(
  "department/deleteDepartment",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await API.deleteDepartment(id);
      dispatch(getDepartment(10));
      return data;
    } catch (error) {
      return rejectWithValue("Failed to delete department.");
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk("department/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get department.");
  }
});
