import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getBarrel, getNext, createBarrel, updateBarrel, handleSearch } from "./thunk";

const initialState = {
  barrels: [],
  edit: false,
  barrel: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingBarrel: false,
  loadingNext: false,
};

export const barrelSlice = createSlice({
  name: "barrel",
  initialState,
  reducers: {
    barrelEditSuccess: (state, action) => {
      state.edit = true;
      state.barrel = state.barrels.find((barrel) => barrel.id === action.payload);
    },
    clearAllBarrel: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingBarrel = false;
      state.barrel = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.barrels = [...state.barrels, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createBarrel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBarrel.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createBarrel.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateBarrel.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateBarrel.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateBarrel.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(isAnyOf(getBarrel.pending, handleSearch.pending), (state) => {
      state.loadingBarrel = true;
    });
    builder.addMatcher(isAnyOf(getBarrel.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingBarrel = false;
      state.barrels = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getBarrel.rejected, handleSearch.rejected), (state) => {
      state.loadingBarrel = false;
    });
  },
});

export const { barrelEditSuccess, clearAllBarrel } = barrelSlice.actions;

export default barrelSlice.reducer;
