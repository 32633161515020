import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getBarrelFill = (postsPerPage) =>
  axiosInstance.get(`api/v1/item/item?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all fiscal sessions
export const getAllBarrelFill = () => axiosInstance.get(`api/v1/item/item?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageBarrelFill = (number, postsPerPage) =>
  axiosInstance.get(`api/v1/item/item?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`);

//creating function
export const createBarrelFill = (body) => axiosInstance.post(`api/v1/item/item`, body);
//updating function
export const updateBarrelFill = (id, body) => axiosInstance.patch(`api/v1/item/item/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(`api/v1/item/item?offset=0&limit=${postsPerPage}&search=${search}`);

// checking the redundant data
export const checkRedundantDataBarrelFillName = (e, cancelToken) =>
  axiosInstance.get(`api/v1/item/item?name=${e.target.value.trim()}`, {
    cancelToken: cancelToken.token,
  });
export const checkRedundantDataBarrelFillCode = (e, cancelToken) =>
  axiosInstance.get(`api/v1/item/item?code=${e.target.value.trim()}`, {
    cancelToken: cancelToken.token,
  });
