import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get barrelInbound
export const getBarrelInbound = createAsyncThunk(
  "barrelInbound/getBarrelInbound",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getBarrelInbound(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all barrelInbound
export const getAllBarrelInbound = createAsyncThunk(
  "barrelInbound/getAllBarrelInbound",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllBarrelInbound();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("barrelInbound/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("barrelInbound/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageBarrelInbound = createAsyncThunk(
  "barrelInbound/getPageBarrelInbound",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageBarrelInbound(number, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// create barrelInbound
export const createBarrelInbound = createAsyncThunk(
  "barrelInbound/createBarrelInbound",
  async (data, { rejectWithValue }) => {
    const { supplier, brand, barrelInboundDetails } = data;
    try {
      const body = JSON.stringify({ supplier, brand, barrelInboundDetails });
      const { data } = await API.createBarrelInbound(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const updateBarrelInbound = createAsyncThunk(
  "barrelInbound/updateBarrelInbound",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { supplier, brand, barrelInboundDetails } = values;
    try {
      const body = JSON.stringify({ supplier, brand, barrelInboundDetails });
      const { data } = await API.updateBarrelInbound(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const getBarrelCodes = createAsyncThunk("barrelInbound/getBarrelCodes", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getBarrelCodes(id);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("barrelInbound/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
