import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getBarrelFill, getNext, createBarrelFill, updateBarrelFill, handleSearch } from "./thunk";

const initialState = {
  barrelFills: [],
  edit: false,
  barrelFill: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingBarrelFill: false,
  loadingNext: false,
};

export const barrelFillSlice = createSlice({
  name: "barrelFill",
  initialState,
  reducers: {
    barrelFillEditSuccess: (state, action) => {
      state.edit = true;
      state.barrelFill = state.barrelFills.find((barrelFill) => barrelFill.id === action.payload);
    },
    clearAllBarrelFill: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingBarrelFill = false;
      state.barrelFill = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.barrelFills = [...state.barrelFills, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createBarrelFill.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBarrelFill.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createBarrelFill.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateBarrelFill.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateBarrelFill.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateBarrelFill.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(isAnyOf(getBarrelFill.pending, handleSearch.pending), (state) => {
      state.loadingBarrelFill = true;
    });
    builder.addMatcher(isAnyOf(getBarrelFill.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingBarrelFill = false;
      state.barrelFills = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getBarrelFill.rejected, handleSearch.rejected), (state) => {
      state.loadingBarrelFill = false;
    });
  },
});

export const { barrelFillEditSuccess, clearAllBarrelFill } = barrelFillSlice.actions;

export default barrelFillSlice.reducer;
