import { RiSettings4Line } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { BiPurchaseTag } from "react-icons/bi";
import { CiInboxIn, CiInboxOut } from "react-icons/ci";
import { FaLocationArrow, FaUser } from "react-icons/fa";

import { MdOutlineInventory } from "react-icons/md";

export const sidebarData = [
  {
    menu: "Inbound Management",
    icon: <CiInboxIn size={20} />,
    key: "inbound",
    sub_menu: [
      {
        name: "Inbound",
        link: "/inbound",
        permissions: ["view_item_inbound", "add_item_inbound", "update_item_inbound"],
      },
    ],
  },
  {
    menu: "Outbound Management",
    icon: <CiInboxOut size={20} />,
    key: "pickList",
    sub_menu: [
      {
        name: "Outbound",
        link: "/outbound",
        permissions: ["view_item_pick_list", "add_item_pick_list", "update_item_pick_list"],
      },
    ],
  },
  {
    menu: "Item Management",
    icon: <BiPurchaseTag size={18} />,
    key: "item",
    sub_menu: [
      {
        name: "Item Setup",
        link: "/item",

        permissions: ["view_item", "add_item", "update_item"],
      },
      {
        name: "Description Setup",
        link: "/description",
        permissions: ["view_description", "add_description", "update_description"],
      },
      {
        name: "Brand Setup",
        link: "/brand",
        permissions: ["view_brand", "add_brand", "update_brand"],
      },
      {
        name: "Unit Setup",
        link: "/unit",
        permissions: ["view_unit", "add_unit", "update_unit"],
      },
    ],
  },
  {
    menu: "Barrel Management",
    icon: <RxDashboard size={18} />,
    key: "barrel",
    sub_menu: [
      {
        name: "Barrel Inbound",
        link: "/barrel-inbound",
        permissions: ["view_barrel_inbound", "add_barrel_inbound", "updated_barrel_inbound"],
      },
      {
        name: "Barrel",
        link: "/barrel",
        permissions: ["view_barrel", "add_barrel", "update_barrel"],
      },
    ],
  },

  {
    menu: "Inventory",
    icon: <MdOutlineInventory size={18} />,
    key: "inventory",
    sub_menu: [
      {
        name: "Stock Analysis",
        link: "/stock-analysis",
        permissions: ["view_stock_analysis"],
      },
      // {
      //   name: "Stock Analysis By Batch",
      //   link: "/stock-analysis-by-batch",
      //   permissions: ["view_stock_analysis_by_batch"],
      // },
      // {
      //   name: "Stock Analysis By Location",
      //   link: "/stock-analysis-by-location",
      //   permissions: ["view_stock_analysis_by_location"],
      // },
      {
        name: "Stock Addition",
        link: "/stock-addition",
        permissions: ["view_stock_addition"],
      },
      {
        name: "Stock Subtraction",
        link: "/stock-subtraction",
        permissions: ["view_stock_subtraction"],
      },
    ],
  },

  {
    menu: "Supplier Management",
    icon: <FaUser size={18} />,
    key: "supplier",
    sub_menu: [
      {
        name: "Supplier Setup",
        link: "/supplier",
        permissions: ["view_supplier", "add_supplier", "update_supplier"],
      },
    ],
  },
  {
    menu: "Department",
    icon: <FaLocationArrow size={18} />,
    key: "location",
    sub_menu: [
      {
        name: "Department Setup",
        link: "/location",
        permissions: ["view_location", "add_location", "update_location"],
      },
      {
        name: "Location Shifting",
        link: "/location-shifting",
        permissions: ["add_location_shift"],
      },
    ],
  },
  // {
  //   menu: "Report",
  //   icon: <BiSolidReport size={18} />,
  //   key: "report",
  //   sub_menu: [
  //     {
  //       name: "Fill Report",
  //       link: "/fill-report",
  //       permissions: ["view_fill_report"],
  //     },
  //     {
  //       name: "Withdraw Report",
  //       link: "/withdraw-report",
  //       permissions: ["view_withdraw_report"],
  //     },
  //   ],
  // },
];

export const admin = [
  {
    menu: "Setting",
    icon: <RiSettings4Line size={18} />,
    sub_menu: [
      {
        name: "System Setup",
        link: "/system-setup",
        key: "coreSetup",
        permissions: ["add_system_setup"],
        child_menu: [
          {
            name: "Organization",
            link: "/organization-setup",
            permissions: ["add_system_setup"],
          },
        ],
      },
      {
        name: "User Setup",
        link: "/user-setup",
        key: "userSetup",
        permissions: [
          "update_user",
          "change_user_password",
          "view_staff",
          "add_user",
          "add_role",
          "view_role",
          "update_role",
        ],
        child_menu: [
          {
            name: "User",
            link: "/user",
            permissions: ["update_user", "change_user_password", "view_staff", "add_user"],
          },
          {
            name: "User Group",
            link: "/user-group",
            permissions: ["add_role", "view_role", "update_role"],
          },
        ],
      },
    ],
  },
];
