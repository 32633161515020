import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import PageNotFound from "../Components/PageNotFound";
import ProtectedRoute from "./ProtectedRoute";

//for refreshing the page when lazy fails loading the component
const lazyWithReload = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false"
    );
    try {
      const component = await componentImport();
      window.localStorage.setItem("page-has-been-force-refreshed", "false");
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      throw error;
    }
  });

const OrganizationSetupAlert = lazyWithReload(() => import("../Components/Alert/OrganizationSetupAlert"));

// core app

const Organization = lazyWithReload(() => import("../Pages/Organization/Page"));
const Dashboard = lazyWithReload(() => import("../Pages/Dashboard/Pages"));

// user setup
const UserListing = lazyWithReload(() => import("../Pages/User/Page"));
const UserGroupListing = lazyWithReload(() => import("../Pages/UserGroup/Page"));
const ChangePassword = lazyWithReload(() => import("../Pages/User/ResetPassword/ChangePassword"));

// item setup
const ItemListing = lazyWithReload(() => import("../Pages/Item/Pages"));
const DescriptionListing = lazyWithReload(() => import("../Pages/Description/Pages"));
const BrandListing = lazyWithReload(() => import("../Pages/Brand/Pages"));
const UnitListing = lazyWithReload(() => import("../Pages/Unit/Pages"));

// party pages

const SupplierListing = lazyWithReload(() => import("../Pages/Supplier/Pages"));
const BarrelInboundListing = lazyWithReload(() => import("../Pages/BarrelInbound/Pages"));
const ItemInboundListing = lazyWithReload(() => import("../Pages/ItemInbound/Pages"));
const BarrelListing = lazyWithReload(() => import("../Pages/Barrel/Pages"));
// stock management pages
const StockAdditionListing = lazyWithReload(() => import("../Pages/StockAddition/Pages"));
const StockSubtractionListing = lazyWithReload(() => import("../Pages/StockSubtraction/Pages"));
const StockAnalysisListing = lazyWithReload(() => import("../Pages/StockAnalysis/Pages"));
const StockAnalysisByBatchListing = lazyWithReload(() => import("../Pages/StockAnalysisByBatch/Pages"));
const StockAnalysisByLocationListing = lazyWithReload(() => import("../Pages/StockAnalysisByLocation/Pages"));
const ItemLedgerListing = lazyWithReload(() => import("../Pages/ItemLedger/Pages"));

// location
const DepartmentListing = lazyWithReload(() => import("../Pages/Department/Page"));
const LocationShiftingListing = lazyWithReload(() => import("../Pages/LocationShifting/Page"));
// pick list
const PickListListing = lazyWithReload(() => import("../Pages/PickLists/Pages"));

const PrivateRoute = () => {
  const isSetupDone = useSelector((state) => state.auth.isSetupDone);

  const ErrorFallback = ({ error }) => {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={""}>
        {isSetupDone ? (
          <Switch>
            <ProtectedRoute exact path="/" component={Dashboard} permission={["view_dashboard"]} />
            <ProtectedRoute exact path="/change-password" component={ChangePassword} permission={""} />

            <ProtectedRoute exact path="/change-password" component={ChangePassword} permission={""} />

            <ProtectedRoute
              path="/organization-setup"
              component={Organization}
              permission={["add_organization", "view_organization", "update_organization"]}
            />
            {/* user setup */}
            <ProtectedRoute
              exact
              path="/user"
              component={UserListing}
              permission={["add_user", "view_user", "update_user"]}
            />
            <ProtectedRoute
              exact
              path="/user-group"
              component={UserGroupListing}
              permission={["add_group", "view_group", "update_group"]}
            />

            {/* item Management */}
            <ProtectedRoute
              exact
              path="/item"
              component={ItemListing}
              permission={["add_item", "view_item", "update_item"]}
            />
            <ProtectedRoute
              exact
              path="/description"
              component={DescriptionListing}
              permission={["add_description", "view_description", "update_description"]}
            />
            <ProtectedRoute
              exact
              path="/brand"
              component={BrandListing}
              permission={["add_brand", "view_brand", "update_brand"]}
            />
            <ProtectedRoute
              exact
              path="/unit"
              component={UnitListing}
              permission={["add_unit", "view_unit", "update_unit"]}
            />

            <ProtectedRoute
              exact
              path="/supplier"
              component={SupplierListing}
              permission={["add_supplier", "view_supplier", "update_supplier"]}
            />
            <ProtectedRoute
              exact
              path="/barrel-inbound"
              component={BarrelInboundListing}
              permission={[
                "add_barrel_inbound",
                "view_barrel_inbound",
                "update_barrel_inbound",
                "approve_barrel_inbound",
              ]}
            />
            <ProtectedRoute
              exact
              path="/inbound"
              component={ItemInboundListing}
              permission={["add_item_inbound", "view_item_inbound", "update_item_inbound", "approve_item_inbound"]}
            />
            {/* <ProtectedRoute exact path="/barrel-fill" component={BarrelFillListing} permission={""} /> */}
            <ProtectedRoute exact path="/barrel" component={BarrelListing} permission={["view_barrel"]} />

            {/* stock management */}
            <ProtectedRoute
              exact
              path="/stock-addition"
              component={StockAdditionListing}
              permission={[
                "view_stock_addition",
                "add_stock_addition",
                "approve_stock_addition",
                "update_stock_addition",
              ]}
            />
            <ProtectedRoute
              exact
              path="/stock-subtraction"
              component={StockSubtractionListing}
              permission={[
                "view_stock_subtraction",
                "add_stock_subtraction",
                "approve_stock_subtraction",
                "update_stock_subtraction",
              ]}
            />
            <ProtectedRoute
              exact
              path="/stock-analysis"
              component={StockAnalysisListing}
              permission={["view_stock_analysis"]}
            />
            <ProtectedRoute
              exact
              path="/stock-analysis-by-batch"
              component={StockAnalysisByBatchListing}
              permission={["view_stock_analysis_by_batch"]}
            />
            <ProtectedRoute
              exact
              path="/stock-analysis-by-location"
              component={StockAnalysisByLocationListing}
              permission={["view_stock_analysis_by_location"]}
            />
            <ProtectedRoute exact path="/item-ledger" component={ItemLedgerListing} permission={""} />
            <ProtectedRoute exact path="/outbound" component={PickListListing} permission={""} />
            {/* location */}
            <ProtectedRoute
              exact
              path="/location"
              component={DepartmentListing}
              permission={["view_location", "update_location", "add_location"]}
            />
            <ProtectedRoute
              exact
              path="/location-shifting"
              component={LocationShiftingListing}
              permission={"location_shift"}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
        ) : (
          <Switch>
            <ProtectedRoute
              path="/organization-setup"
              component={Organization}
              permission={["add_organization", "view_organization", "update_organization"]}
            />
            <Route path="*" component={OrganizationSetupAlert} />
          </Switch>
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

export default withRouter(PrivateRoute);
