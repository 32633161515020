import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get itemInbound
export const getItemInbound = createAsyncThunk(
  "itemInbound/getItemInbound",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getItemInbound(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all itemInbound
export const getAllItemInbound = createAsyncThunk("itemInbound/getAllItemInbound", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllItemInbound();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("itemInbound/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("itemInbound/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageItemInbound = createAsyncThunk(
  "itemInbound/getPageItemInbound",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageItemInbound(number, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get particular page
export const getSpecificBarrelAction = createAsyncThunk(
  "itemInbound/getSpecificBarrelAction",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificBarrelAction(id);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// create itemInbound
export const createItemInbound = createAsyncThunk(
  "itemInbound/createItemInbound",
  async (data, { rejectWithValue }) => {
    const { itemInboundDetails } = data;
    try {
      const body = JSON.stringify({ itemInboundDetails });
      const { data } = await API.createItemInbound(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const updateItemInbound = createAsyncThunk(
  "itemInbound/updateItemInbound",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { itemInboundDetails } = values;
    try {
      const body = JSON.stringify({ itemInboundDetails });
      const { data } = await API.updateItemInbound(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// get item inbound pick list
export const getItemInboundPickList = createAsyncThunk(
  "itemInbound/getItemInboundPickList",
  async (data, { rejectWithValue }) => {
    const { item = "", quantity = "", batchNo = "" } = data;
    try {
      const { data } = await API.getItemInboundPickList({ item, quantity, batchNo });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk("itemInbound/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
