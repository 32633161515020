import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getUnit, getAllUnit, getNext, getPrevious, getPageUnit, createUnit, updateUnit, handleSearch } from "./thunk";

const initialState = {
  units: [],
  edit: false,
  unit: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingUnit: false,
  loadingNext: false,
};

export const unitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    unitEditSuccess: (state, action) => {
      state.edit = true;
      state.unit = state.units.find((unit) => unit.id === action.payload);
    },
    clearAllUnit: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingUnit = false;
      state.unit = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.units = [...state.units, ...action.payload.results];
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createUnit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUnit.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createUnit.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateUnit.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateUnit.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateUnit.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(isAnyOf(getUnit.pending, handleSearch.pending), (state) => {
      state.loadingUnit = true;
    });
    builder.addMatcher(isAnyOf(getUnit.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingUnit = false;
      state.units = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getUnit.rejected, handleSearch.rejected), (state) => {
      state.loadingUnit = false;
    });
  },
});

export const { unitEditSuccess, clearAllUnit } = unitSlice.actions;

export default unitSlice.reducer;
