import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import {
  getItemInbound,
  getNext,
  createItemInbound,
  updateItemInbound,
  handleSearch,
  getSpecificBarrelAction,
  getItemInboundPickList,
} from "./thunk";

const initialState = {
  itemInbounds: [],
  edit: false,
  itemInbound: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingItemInbound: false,
  loadingNext: false,
  itemInboundDetails: [],
  loadingBarrelActions: false,
  barrelActions: [],
  loadingPickLists: false,
  itemPickLists: [],
};

export const itemInboundSlice = createSlice({
  name: "itemInbound",
  initialState,
  reducers: {
    itemInboundDetailsAdded: (state, action) => {
      state.itemInboundDetails = action.payload;
    },
    itemInboundDetailsUpdated: (state, action) => {
      state.itemInboundDetails = action.payload;
    },
    itemInboundDetailsDeleted: (state, action) => {
      state.itemInboundDetails = state.itemInboundDetails.filter((detail) => detail.unique !== action.payload);
    },
    itemInboundEditSuccess: (state, action) => {
      const tempItemInbound = state.itemInbounds.find((itemInbound) => itemInbound.id === action.payload);
      state.edit = true;
      state.itemInbound = tempItemInbound;
      state.itemInboundDetails = tempItemInbound.itemInboundDetails?.map((temp) => ({ ...temp, unique: uuidv4() }));
    },
    clearBarrelActions: (state) => {
      state.loadingBarrelActions = false;
      state.barrelActions = [];
    },
    clearPickLists: (state) => {
      state.loadingPickLists = false;
      state.itemPickLists = [];
    },
    clearAllItemInbound: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingItemInbound = false;
      state.itemInbound = null;
      state.itemInboundDetails = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.itemInbounds = [...state.itemInbounds, ...action.payload.results];
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });

    builder.addCase(createItemInbound.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createItemInbound.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createItemInbound.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateItemInbound.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateItemInbound.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateItemInbound.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addCase(getSpecificBarrelAction.pending, (state) => {
      state.loadingBarrelActions = true;
    });
    builder.addCase(getSpecificBarrelAction.fulfilled, (state, action) => {
      state.loadingBarrelActions = false;
      state.barrelActions = action.payload.results;
    });
    builder.addCase(getSpecificBarrelAction.rejected, (state) => {
      state.loadingBarrelActions = false;
    });
    builder.addCase(getItemInboundPickList.pending, (state) => {
      state.loadingPickLists = true;
    });
    builder.addCase(getItemInboundPickList.fulfilled, (state, action) => {
      state.loadingPickLists = false;
      state.itemPickLists = action.payload.results;
    });
    builder.addCase(getItemInboundPickList.rejected, (state) => {
      state.loadingPickLists = false;
    });
    builder.addMatcher(isAnyOf(getItemInbound.pending, handleSearch.pending), (state) => {
      state.loadingItemInbound = true;
    });
    builder.addMatcher(isAnyOf(getItemInbound.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingItemInbound = false;
      state.edit = false;
      state.itemInbounds = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getItemInbound.rejected, handleSearch.rejected), (state) => {
      state.loadingItemInbound = false;
      state.edit = false;
    });
  },
});

export const {
  itemInboundDetailsAdded,
  itemInboundDetailsDeleted,
  itemInboundDetailsUpdated,
  itemInboundEditSuccess,
  clearBarrelActions,
  clearPickLists,
  clearAllItemInbound,
} = itemInboundSlice.actions;

export default itemInboundSlice.reducer;
