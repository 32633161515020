import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getStockAnalysis = (postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-stock-analysis?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all fiscal sessions
export const getAllStockAnalysis = () => axiosInstance.get(`api/v1/barrel-app/barrel-stock-analysis?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageStockAnalysis = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/barrel-app/barrel-stock-analysis?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`
  );

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-stock-analysis?offset=0&limit=${postsPerPage}&search=${search}`);
