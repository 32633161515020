import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get brand
export const getBrand = createAsyncThunk("brand/getBrand", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getBrand(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get all brand
export const getAllBrand = createAsyncThunk("brand/getAllBrand", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllBrand();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("brand/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("brand/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageBrand = createAsyncThunk("brand/getPageBrand", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageBrand(number, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create brand
export const createBrand = createAsyncThunk("brand/createBrand", async (data, { rejectWithValue }) => {
  const { name, isActive } = data;
  try {
    const body = JSON.stringify({
      name,
      isActive,
    });
    const { data } = await API.createBrand(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const updateBrand = createAsyncThunk("brand/updateBrand", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { name, isActive } = values;
  try {
    const body = JSON.stringify({
      name,
      isActive,
    });
    const { data } = await API.updateBrand(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("brand/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
