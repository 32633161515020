import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getItemInbound = (postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-item-inbound?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all fiscal sessions
export const getAllItemInbound = () => axiosInstance.get(`api/v1/barrel-app/barrel-item-inbound?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageItemInbound = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/barrel-app/barrel-item-inbound?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`
  );
// get barrel action from item inbound details
export const getSpecificBarrelAction = (id) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-action?action_type=fill&item_in_bound_detail=${id}`);
//creating function
export const createItemInbound = (body) => axiosInstance.post(`api/v1/barrel-app/barrel-item-inbound`, body);
//updating function
export const updateItemInbound = (id, body) => axiosInstance.patch(`api/v1/barrel-app/barrel-item-inbound/${id}`, body);

// get item pick list
export const getItemInboundPickList = ({ item, quantity, batchNo }) =>
  axiosInstance.get(
    `api/v1/barrel-app/pick-list?item=${item ? item : ""}&quantity=${quantity ? quantity : ""}&batch_no=${
      batchNo ? batchNo : ""
    }`
  );
//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-item-inbound?offset=0&limit=${postsPerPage}&search=${search}`);
