import axios from "axios";
import deleteCookie from "./Cookies/deleteCookie";
import getCookie from "./Cookies/getCookie";
import setCookie from "./Cookies/setCookie";
import { store } from "../Store/store";
import { errorFunction } from "../Components/Alert/Alert";
import { authError } from "../Redux/Auth/authSlice";

const axiosInstance = axios.create({
  baseURL:
    localStorage.getItem("url") !== null &&
    // `http://${localStorage.getItem("url")}:8008`,
    `https://${localStorage.getItem("url")}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosRFIDInstance = axios.create({
  baseURL: process.env.REACT_APP_RFID_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosBranchInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const publicAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

publicAxiosInstance.interceptors.request.use(
  (config) => {
    const baseURL = config.data
      ? `https://${config.data.subDomain}`
      : //  `http://${config.data.subDomain}:8008`
        process.env.REACT_APP_BASE_URL;

    config.baseURL = baseURL;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// interceptor request handler
axiosInstance.interceptors.request.use(
  (config) => {
    if (!window.navigator.onLine) {
      errorFunction(`No Internet Connection !!!`);
    } else if (window.navigator.onLine) {
      // get access token from cookie
      config.headers = {
        Authorization: getCookie("accessToken") ? `Bearer ${getCookie("accessToken")}` : "",
      };

      config.baseURL =
        localStorage.getItem("url") !== null
          ? //
            `https://${localStorage.getItem("url")}`
            // `http://${localStorage.getItem("url")}:8008`
          : process.env.REACT_APP_BASE_URL;
      config.params = config.params || {};
      // sent appType & device type in every request (also checks if payload data is form data or JSON string)
      if (config.data !== undefined) {
        if (Array.isArray(config.data)) {
          config.data = config.data?.map((item) => ({
            ...item,
            appType: 1,
            deviceType: 2,
          }));
        } else if (typeof config.data === "object") {
          let bodyFormData = config.data;
          bodyFormData.append("appType", "1");
          bodyFormData.append("deviceType", "2");
          config.data = bodyFormData;
        } else if (typeof config.data === "string") {
          let bodyData = JSON.parse(config.data);

          config.data = { ...bodyData, appType: 1, deviceType: 2 };
        }
      }
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response handling
axiosInstance.interceptors.response.use(
  (res) => res,
  async (error) => {
    //refresh token
    const originalRequest = error.config;
    //when refresh token is also not valid
    if (error.response.status === 401 && originalRequest.url === `api/v1/user-app/login/refresh`) {
      store.dispatch(authError());
      return errorFunction(`Please login.`);
    }
    //accessing new access token from refresh token
    else if (error.response?.data.code === "token_not_valid" && !originalRequest._retry) {
      //call for refresh token

      originalRequest._retry = true;
      try {
        const body = JSON.stringify({
          refresh: getCookie("refreshToken"),
        });
        deleteCookie("accessToken");
        const response = await axiosInstance.post(`api/v1/user-app/login/refresh`, body);
        if (response.status === 200) {
          setCookie("accessToken", response?.data.access);
          originalRequest.headers["Authorization"] = `Bearer ${response?.data.access}`;
          return axiosInstance(originalRequest);
        }
      } catch (error) {
        store.dispatch(authError());
      }
    }

    //server down
    else if (error?.message === "Network Error") {
      errorFunction("Internal Server Error. Contact IT manager !!!");
    } else if (error.response?.status === 500) {
      errorFunction("Internal Server Error. Contact IT manager !!!");
    } else if (error.response?.status === 502) {
      errorFunction("Bad Gateway. Contact IT manager !!!");
    } else if (error.response?.status === 403) {
      errorFunction("Permission Denied. Contact IT manager !!!");
    } else if (error.response?.status === 404) {
      errorFunction("Page Not Found !!!");
    }
    //unauthorized user
    else if (error.response?.status === 401 || error.message === "Please login.") {
      store.dispatch(authError());
    }

    // else if (error.response?.status === 400) {
    //   errorFunction(error.response.data.detail);
    // }

    return Promise.reject(error);
  }
);

export default axiosInstance;
