import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getBrand = (postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-brand?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all fiscal sessions
export const getAllBrand = () => axiosInstance.get(`api/v1/barrel-app/barrel-brand?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageBrand = (number, postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-brand?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`);

//creating function
export const createBrand = (body) => axiosInstance.post(`api/v1/barrel-app/barrel-brand`, body);
//updating function
export const updateBrand = (id, body) => axiosInstance.patch(`api/v1/barrel-app/barrel-brand/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-brand?offset=0&limit=${postsPerPage}&search=${search}`);

// checking the redundant data
export const checkRedundantDataName = (e, cancelToken) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-brand?name=${e.target.value.trim()}`, {
    cancelToken: cancelToken.token,
  });
