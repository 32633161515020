import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import deleteCookie from "../../Utils/Cookies/deleteCookie";
import setCookie from "../../Utils/Cookies/setCookie";
import {
  login,
  logout,
  resetPassword,
  confirmPassword,
  changePassword,
  getAllBranches,
  checkSetup,
} from "./thunk";
// initial states for auth
const initialState = {
  isAuthenticated: false,
  loadingLogin: false,
  loadingLogout: false,
  loadingReset: false,
  loadingResetPassword: false,
  loadingChangePassword: false,
  loadingBranches: false,
  loadingIsSetupDone: false,
  userName: null,
  message: [],
  userId: null,
  permissions: [],
  authError: false,
  isSuperuser: false,
  img: null,
  branches: [],
  groups: [],
  isSetupDone: [],
  branchName: "",
  settingAlert: false,
};

// createSlice for actions and reducers
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setBranch: (state, action) => {
      state.branches = action.payload;
    },
    authError: (state) => {
      storage.removeItem("persist:Cubix");
      deleteCookie("accessToken");
      deleteCookie("refreshToken");
      deleteCookie("projectName");
      state.isAuthenticated = false;
      state.loading = false;
      state.userName = null;
      state.userId = null;
      state.authError = true;
      state.isSuperuser = false;
      state.permissions = [];
      state.img = null;
    },
    resetSuccess: (state, action) => {
      state.loadingResetPassword = false;
      state.message = action.payload;
      state.authError = false;
    },
    setSettingAlert: (state, action) => {
      state.settingAlert = action.payload;
    },
   
  },
  //   extra reducers for actions that include api call
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loadingLogin = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const permissions = action.payload.permissions;
      const userPermissions = permissions?.map((permission) => {
        return permission?.codeName;
      });
      state.loadingLogin = false;
      storage.removeItem("persist:Cubix");
      setCookie("accessToken", action.payload.tokens.access, {
        // secure: false,
        "max-age": 360000,
      });
      setCookie("refreshToken", action.payload.tokens.refresh, {
        // secure:false,
        "max-age": 3600000,
      });
      
      setCookie("projectName", process.env.REACT_APP_PROJECT_NAME, {
        // secure:false,
        "max-age": 3600000,
      });

      state.isAuthenticated = true;
      state.userName = action.payload.userName;
      state.userId = action.payload.id;
      state.authError = false;
      state.isSuperuser = action.payload.isSuperuser;
      state.permissions = userPermissions;
      state.groups = action.payload.groups;
      state.img = action.payload.photo;
      state.isSetupDone = action.payload.isSetupDone;
      state.settingAlert = !action.payload.isSetupDone ? true : false;
      state.branchName = action.payload.branchName;
    });
    builder.addCase(login.rejected, (state) => {
      state.isAuthenticated = false;
      state.loadingLogin = false;
      state.img = null;
    });
    builder.addCase(logout.pending, (state) => {
      state.loadingLogout = true;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      storage.removeItem("persist:Cubix");
      deleteCookie("accessToken");
      deleteCookie("refreshToken");
      deleteCookie("projectName");

      state.isAuthenticated = false;
      state.loadingLogout = false;
      state.userName = null;
      state.userId = null;
      state.authError = false;
      state.isSuperuser = false;
      state.permissions = [];
      state.groups = [];
      state.img = null;
    });
    builder.addCase(logout.rejected, (state) => {
      state.loadingLogout = false;
      state.authError = false;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.loadingResetPassword = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loadingResetPassword = false;
      state.message = action.payload;
      state.authError = false;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.loadingResetPassword = false;
      state.authError = false;
    });
    builder.addCase(confirmPassword.pending, (state) => {
      state.loadingReset = true;
    });
    builder.addCase(confirmPassword.fulfilled, (state) => {
      state.loadingReset = false;
      state.authError = false;
    });
    builder.addCase(confirmPassword.rejected, (state) => {
      state.loadingReset = false;
      state.authError = false;
    });
    builder.addCase(changePassword.pending, (state) => {
      state.loadingChangePassword = true;
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loadingChangePassword = false;
    });
    builder.addCase(changePassword.rejected, (state) => {
      state.loadingChangePassword = false;
    });
    builder.addCase(getAllBranches.pending, (state) => {
      state.loadingBranches = true;
    });
    builder.addCase(getAllBranches.fulfilled, (state, action) => {
      state.loadingBranches = false;
      state.branches = action.payload.results;
    });
    builder.addCase(getAllBranches.rejected, (state) => {
      state.loadingBranches = false;
    });
    builder.addCase(checkSetup.pending, (state) => {
      state.loadingIsSetupDone = true;
    });
    builder.addCase(checkSetup.fulfilled, (state, action) => {
      state.loadingIsSetupDone = false;
      state.isSetupDone = action.payload.isSetupDone;
    });
    builder.addCase(checkSetup.rejected, (state) => {
      state.loadingIsSetupDone = false;
    });
  },
});

// export actions that does not involve api calls
export const {
  setBranch,
  authError,
  resetSuccess,
  setSettingAlert,
} = authSlice.actions;
// export reducer
export default authSlice.reducer;