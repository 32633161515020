import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getBarrelInbound = (postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-inbound?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all fiscal sessions
export const getAllBarrelInbound = () => axiosInstance.get(`api/v1/barrel-app/barrel-inbound?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageBarrelInbound = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/barrel-app/barrel-inbound?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`
  );

export const getBarrelCodes = (id) => axiosInstance.get(`api/v1/barrel-app/barrel-inbound-code?barrel_detail=${id}&limit=0`);
//creating function
export const createBarrelInbound = (body) => axiosInstance.post(`api/v1/barrel-app/barrel-inbound`, body);
//updating function
export const updateBarrelInbound = (id, body) => axiosInstance.patch(`api/v1/barrel-app/barrel-inbound/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-inbound?offset=0&limit=${postsPerPage}&search=${search}`);
