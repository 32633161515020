import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get pickList
export const getPickList = createAsyncThunk("pickList/getPickList", async ({ postsPerPage }, { rejectWithValue }) => {
  try {
    const { data } = await API.getPickList({ postsPerPage });
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get all pickList
export const getAllPickList = createAsyncThunk("pickList/getAllPickList", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllPickList();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get next
export const getNext = createAsyncThunk("pickList/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create pick list
export const createPickList = createAsyncThunk("pickList/createPickList", async (createData, { rejectWithValue }) => {
  const { pickDetails } = createData;
  try {
    const body = JSON.stringify({ pickDetails });
    const { data } = await API.createPickList(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// update pick list
export const updatePickList = createAsyncThunk("pickList/updatePickList", async (updateData, { rejectWithValue }) => {
  const { id, values } = updateData;
  const { pickDetails } = values;
  try {
    const body = JSON.stringify({ pickDetails });
    const { data } = await API.updatePickList(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("pickList/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
