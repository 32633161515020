import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get description
export const getDescription = createAsyncThunk(
  "description/getDescription",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getDescription(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all description
export const getAllDescription = createAsyncThunk("description/getAllDescription", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllDescription();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("description/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("description/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageDescription = createAsyncThunk(
  "description/getPageDescription",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageDescription(number, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// create description
export const createDescription = createAsyncThunk(
  "description/createDescription",
  async (data, { rejectWithValue }) => {
    const { name, code, isPeated, isActive } = data;
    try {
      const body = JSON.stringify({ name, code, isPeated, isActive });
      const { data } = await API.createDescription(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const updateDescription = createAsyncThunk(
  "description/updateDescription",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { name, code, isPeated, isActive } = values;
    try {
      const body = JSON.stringify({ name, code, isPeated, isActive });
      const { data } = await API.updateDescription(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk("description/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
