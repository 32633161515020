import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getStockAnalysisByLocation = (postsPerPage) =>
  axiosInstance.get(`api/v1/stock/stock-analysis-by-location?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all fiscal sessions
export const getAllStockAnalysisByLocation = () =>
  axiosInstance.get(`api/v1/stock/stock-analysis-by-location?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageStockAnalysisByLocation = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/stock/stock-analysis-by-location?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`
  );

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(`api/v1/stock/stock-analysis-by-location?offset=0&limit=${postsPerPage}&search=${search}`);
