import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";
import { errorFunction, successFunction } from "../../../Components/Alert/Alert";

// get location
export const getLocation = createAsyncThunk(
  "location/getLocation",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getLocation(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get locations.");
    }
  }
);

// get all locations
export const getAllLocation = createAsyncThunk(
  "location/getAllLocation",
  async (departmentId, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllLocation(departmentId);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get locations.");
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk(
  "location/getPrevious",
  async (previous, { rejectWithValue }) => {
    try {
      const { data } = await API.getPrevious(previous);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get locations.");
    }
  }
);
// get next
export const getNext = createAsyncThunk(
  "location/getNext",
  async (next, { rejectWithValue }) => {
    try {
      const { data } = await API.getNext(next);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get locations.");
    }
  }
);

// get particular page
export const getPageLocation = createAsyncThunk(
  "location/getPageLocation",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageLocation(number, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue("Failed to get locations.");
    }
  }
);

// create location
export const createLocation = createAsyncThunk(
  "location/createLocation",
  async (data, { rejectWithValue, dispatch }) => {
    const { departmentId, values } = data;
    const { name, prefix, parent } = values;
    try {
      const parentId = parent === null ? null : parent.id;
      const body = JSON.stringify({
        name,
        prefix,
        parent: parentId,
        department: departmentId,
      });
      const { data } = await API.createLocation(body);
      dispatch(getAllLocation(departmentId));
      successFunction("Location created successfully");
      return data;
    } catch (error) {
      errorFunction("Fail to create location.");
      return rejectWithValue("Failed to create location.");
    }
  }
);
// update location
export const updateLocation = createAsyncThunk(
  "location/updateLocation",
  async ([id, values, departmentId], { rejectWithValue, dispatch }) => {
    // const { id, departmentId, values } = data;
    const { name, parent, remarks, active } = values;
    try {
      const parentId = parent === null ? null : parent.id;
      const body = JSON.stringify({
        name,
        parent: parentId,
        remarks,
        active,
        department: departmentId,
      });
      const { data } = await API.updateLocation(id, body);
      successFunction("Location updated successfully");
      dispatch(getAllLocation(departmentId));
      return data;
    } catch (error) {
      errorFunction("Fail to update location.");
      return rejectWithValue("Failed to update location.");
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "location/handleSearch",
  async (data, { rejectWithValue }) => {
    const { search, postsPerPage } = data;
    try {
      const { data } = await API.handleSearch(search, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get location.");
    }
  }
);

// get info of pack code
export const getInfoOfPackCode = createAsyncThunk(
  "location/getInfoOfPackCode",
  async (code, { rejectWithValue }) => {
    try {
      const { data } = await API.getInfoOfPackCode(code);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get info of pack code.");
    }
  }
);

// location shift
export const locationShift = createAsyncThunk(
  "location/locationShift",
  async (data, { rejectWithValue }) => {
    const { packTypeCodeId, locationCode } = data;
    try {
      const body = JSON.stringify({ packTypeCodeId, locationCode });
      const { data } = await API.locationShift(body);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
