import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getStockAddition, getNext, handleSearch, createStockAddition } from "./thunk";

const initialState = {
  stockAdditions: [],
  count: null,
  next: null,
  previous: null,
  loadingStockAddition: false,
  loading: false,
  loadingUpdated: false,
  loadingNext: false,
};

export const stockAdditionSlice = createSlice({
  name: "stockAddition",
  initialState,
  reducers: {
    clearAllStockAddition: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.stockAdditions = [...state.stockAdditions, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createStockAddition.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createStockAddition.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createStockAddition.rejected, (state) => {
      state.loading = false;
    });
    builder.addMatcher(isAnyOf(getStockAddition.pending, handleSearch.pending), (state) => {
      state.loadingStockAddition = true;
    });
    builder.addMatcher(isAnyOf(getStockAddition.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingStockAddition = false;
      state.stockAdditions = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getStockAddition.rejected, handleSearch.rejected), (state) => {
      state.loadingStockAddition = false;
    });
  },
});

export const { clearAllStockAddition } = stockAdditionSlice.actions;

export default stockAdditionSlice.reducer;
