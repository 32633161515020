import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getLocation,
  getAllLocation,
  getPrevious,
  getNext,
  getPageLocation,
  createLocation,
  updateLocation,
  handleSearch,
  getInfoOfPackCode,
  locationShift,
} from "./thunk";

// initialState
const initialState = {
  locations: [],
  edit: false,
  location: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  parent: null,
  packCodeInfo: null,
};

// reducer and actions for location
export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    locationEditSuccess: (state, action) => {
      state.edit = true;
      state.location = state.locations.find(
        (location) => location.id === action.payload
      );
      state.loading = false;
      state.loadingUpdated = false;
    },
    addLocation: (state, action) => {
      state.parent = action.payload;
    },
    clearPackCodeInfo: (state, action) => {
      state.packCodeInfo = null;
    },
    clearEditLocation: (state) => {
      state.edit = false;
      state.location = null;
      state.loading = false;
      state.loadingUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
      state.locations = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
      state.loadingUpdated = false;
    });
    builder.addCase(getLocation.rejected, (state) => {
      state.loading = false;
      state.edit = false;
      state.loadingUpdated = false;
    });

    builder.addCase(updateLocation.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateLocation.fulfilled, (state, action) => {
      let newLocations = state.locations.map((location) =>
        location.id === action.payload.id ? action.payload : location
      );
      state.loadingUpdated = false;
      state.locations = newLocations;
      state.edit = false;
      state.location = null;
      state.loading = false;
    });
    builder.addCase(updateLocation.rejected, (state) => {
      state.loadingUpdated = false;
      state.edit = false;
      state.location = null;
    });
    builder.addCase(getInfoOfPackCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInfoOfPackCode.fulfilled, (state, action) => {
      state.loading = false;
      state.packCodeInfo = action.payload;
    });
    builder.addCase(getInfoOfPackCode.rejected, (state) => {
      state.loading = false;
    });
    builder.addMatcher(
      isAnyOf(
        getAllLocation.pending,
        getPrevious.pending,
        getNext.pending,
        getPageLocation.pending,
        handleSearch.pending
      ),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllLocation.fulfilled,
        getPrevious.fulfilled,
        getNext.fulfilled,
        getPageLocation.fulfilled,
        handleSearch.fulfilled
      ),
      (state, action) => {
        state.loading = false;
        state.locations = action.payload.results;
        state.count = action.payload.count;
        state.previous = action.payload.previous;
        state.next = action.payload.next;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllLocation.rejected,
        getPrevious.rejected,
        getNext.rejected,
        getPageLocation.rejected,
        handleSearch.rejected
      ),
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(createLocation.pending, locationShift.pending),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(createLocation.fulfilled, locationShift.fulfilled),
      (state, action) => {
        state.loading = false;
        state.edit = false;
      }
    );
    builder.addMatcher(
      isAnyOf(createLocation.rejected, locationShift.rejected),
      (state) => {
        state.loading = false;
        state.edit = false;
      }
    );
  },
});

export const {
  locationEditSuccess,
  addLocation,
  clearEditLocation,
  clearPackCodeInfo,
} = locationSlice.actions;

export default locationSlice.reducer;
