import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getBrand, getNext, createBrand, updateBrand, handleSearch } from "./thunk";

const initialState = {
  brands: [],
  edit: false,
  brand: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingBrand: false,
  loadingNext: false,
};

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    brandEditSuccess: (state, action) => {
      state.edit = true;
      state.brand = state.brands.find((brand) => brand.id === action.payload);
    },
    clearAllBrand: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingBrand = false;
      state.brand = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.brands = [...state.brands, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createBrand.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBrand.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createBrand.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateBrand.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateBrand.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateBrand.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(isAnyOf(getBrand.pending, handleSearch.pending), (state) => {
      state.loadingBrand = true;
    });
    builder.addMatcher(isAnyOf(getBrand.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingBrand = false;
      state.brands = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getBrand.rejected, handleSearch.rejected), (state) => {
      state.loadingBrand = false;
    });
  },
});

export const { brandEditSuccess, clearAllBrand } = brandSlice.actions;

export default brandSlice.reducer;
