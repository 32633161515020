import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getDescription = (postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-item-description?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all fiscal sessions
export const getAllDescription = () => axiosInstance.get(`api/v1/barrel-app/barrel-item-description?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageDescription = (number, postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-item-description?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`);

//creating function
export const createDescription = (body) => axiosInstance.post(`api/v1/barrel-app/barrel-item-description`, body);
//updating function
export const updateDescription = (id, body) => axiosInstance.patch(`api/v1/barrel-app/barrel-item-description/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-item-description?offset=0&limit=${postsPerPage}&search=${search}`);


