import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loadingData: false,
  lastWeekStaticalData: null,
  currentWeekStaticalData: null,
  topPartySalesList: null,
  topItemSalesList: null,
  purchaseOrderPicked: null,
  customerOrderPicked: null,
  lineChart: null,
  dashboardData: null,
  accountDashboardData: null,
};
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
});
export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
