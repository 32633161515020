import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get supplier
export const getSupplier = createAsyncThunk("supplier/getSupplier", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getSupplier(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get all supplier
export const getAllSupplier = createAsyncThunk("supplier/getAllSupplier", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllSupplier();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("supplier/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("supplier/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageSupplier = createAsyncThunk("supplier/getPageSupplier", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageSupplier(number, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create supplier
export const createSupplier = createAsyncThunk("supplier/createSupplier", async (data, { rejectWithValue }) => {
  const { name, isActive } = data;
  try {
    const body = JSON.stringify({ name, isActive });
    const { data } = await API.createSupplier(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const updateSupplier = createAsyncThunk("supplier/updateSupplier", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { name, isActive } = values;
  try {
    const body = JSON.stringify({ name, isActive });
    const { data } = await API.updateSupplier(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("supplier/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
