import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getDescription, getNext, createDescription, updateDescription, handleSearch } from "./thunk";

const initialState = {
  descriptions: [],
  edit: false,
  description: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingDescription: false,
  loadingNext: false,
};

export const descriptionSlice = createSlice({
  name: "description",
  initialState,
  reducers: {
    descriptionEditSuccess: (state, action) => {
      state.edit = true;
      state.description = state.descriptions.find((description) => description.id === action.payload);
    },
    clearAllDescription: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingDescription = false;
      state.description = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.descriptions = [...state.descriptions, ...action.payload.results];
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createDescription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDescription.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createDescription.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateDescription.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateDescription.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateDescription.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(isAnyOf(getDescription.pending, handleSearch.pending), (state) => {
      state.loadingDescription = true;
    });
    builder.addMatcher(isAnyOf(getDescription.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingDescription = false;
      state.descriptions = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getDescription.rejected, handleSearch.rejected), (state) => {
      state.loadingDescription = false;
    });
  },
});

export const { descriptionEditSuccess, clearAllDescription } = descriptionSlice.actions;

export default descriptionSlice.reducer;
