import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getStockAnalysisByLocation, getNext, handleSearch } from "./thunk";

const initialState = {
  locationStockAnalyses: [],
  count: null,
  next: null,
  previous: null,
  loadingNext: false,
  loadingStockAnalysisByLocation: false,
};

export const stockAnalysisByLocationSlice = createSlice({
  name: "stockAnalysisByLocation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.locationStockAnalyses = [...state.locationStockAnalyses, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addMatcher(isAnyOf(getStockAnalysisByLocation.pending, handleSearch.pending), (state) => {
      state.loadingStockAnalysisByLocation = true;
    });
    builder.addMatcher(isAnyOf(getStockAnalysisByLocation.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingStockAnalysisByLocation = false;
      state.locationStockAnalyses = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getStockAnalysisByLocation.rejected, handleSearch.rejected), (state) => {
      state.loadingStockAnalysisByLocation = false;
    });
  },
});

export const {} = stockAnalysisByLocationSlice.actions;

export default stockAnalysisByLocationSlice.reducer;
