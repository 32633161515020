import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get barrelFill
export const getBarrelFill = createAsyncThunk("barrelFill/getBarrelFill", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getBarrelFill(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get all barrelFill
export const getAllBarrelFill = createAsyncThunk("barrelFill/getAllBarrelFill", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllBarrelFill();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("barrelFill/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("barrelFill/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageBarrelFill = createAsyncThunk("barrelFill/getPageBarrelFill", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageBarrelFill(number, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create barrelFill
export const createBarrelFill = createAsyncThunk("barrelFill/createBarrelFill", async (data, { rejectWithValue }) => {
  const { name, code, rate, materialType, yarnQuality, description, active } = data;
  try {
    const body = JSON.stringify({
      name,
      code,
      rate,
      materialType,
      yarnQuality,

      description,
      active,
    });
    const { data } = await API.createBarrelFill(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const updateBarrelFill = createAsyncThunk("barrelFill/updateBarrelFill", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { name, code, rate, yarnType, materialType, barrelFillType, yarnQuality, description, active } = values;
  try {
    const body = JSON.stringify({
      name,
      code,
      rate,
      yarnType,
      materialType,
      yarnQuality,
      barrelFillType,
      description,
      active,
    });
    const { data } = await API.updateBarrelFill(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("barrelFill/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
