import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getItemLedger = (postsPerPage) =>
  axiosInstance.get(`api/v1/stock/ledger?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all fiscal sessions
export const getAllItemLedger = () => axiosInstance.get(`api/v1/stock/ledger?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageItemLedger = (number, postsPerPage) =>
  axiosInstance.get(`api/v1/stock/ledger?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`);

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(`api/v1/stock/ledger?offset=0&limit=${postsPerPage}&search=${search}`);
