import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getItemLedger, getAllItemLedger, getNext, getPrevious, getPageItemLedger, handleSearch } from "./thunk";

const initialState = {
  itemLedgers: [],
  count: null,
  next: null,
  previous: null,
  loadingItemLedger: false,
  loadingNext: false,
};

export const itemLedgerSlice = createSlice({
  name: "itemLedger",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.itemLedgers = [...state.itemLedgers, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addMatcher(isAnyOf(getItemLedger.pending, handleSearch.pending), (state) => {
      state.loadingItemLedger = true;
    });
    builder.addMatcher(isAnyOf(getItemLedger.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingItemLedger = false;
      state.itemLedgers = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getItemLedger.rejected, handleSearch.rejected), (state) => {
      state.loadingItemLedger = false;
    });
  },
});

export const {} = itemLedgerSlice.actions;

export default itemLedgerSlice.reducer;
