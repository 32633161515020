import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getPickList = ({ postsPerPage }) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-item-pick-list?offset=0&limit=${postsPerPage}`);

//obtaining all fiscal sessions
export const getAllPickList = () => axiosInstance.get(`api/v1/barrel-app/barrel-item-pick-list?ordering=-id`);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

export const createPickList = (body) => axiosInstance.post(`api/v1/barrel-app/barrel-item-pick-list`, body);

export const updatePickList = (id, body) => axiosInstance.patch(`api/v1/barrel-app/barrel-item-pick-list/${id}`, body);

//searching function
export const handleSearch = ({ postsPerPage, search }) =>
  axiosInstance.get(`api/v1/barrel-app/barrel-item-pick-list?offset=0&limit=${postsPerPage}&search=${search}`);
