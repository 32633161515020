import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import {
  getBarrelInbound,
  getNext,
  createBarrelInbound,
  updateBarrelInbound,
  handleSearch,
  getBarrelCodes,
  getNextBarrelCodes,
} from "./thunk";

const capacityList = [
  { id: "130", name: "130 Lt" },
  { id: "200", name: "200 Lt" },
  { id: "225", name: "225 Lt" },
  { id: "250", name: "250 Lt" },
  { id: "500", name: "500 Lt" },
];

const initialState = {
  barrelInbounds: [],
  edit: false,
  barrelInbound: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingBarrelInbound: false,
  loadingNext: false,
  barrelInboundDetails: [],
  loadingBarrelCodes: false,
  barrelCodes: [],
};

export const barrelInboundSlice = createSlice({
  name: "barrelInbound",
  initialState,
  reducers: {
    barrelInboundDetailsAdded: (state, action) => {
      state.barrelInboundDetails = action.payload;
    },
    barrelInboundDetailsUpdated: (state, action) => {
      state.barrelInboundDetails = action.payload;
    },
    barrelInboundDetailsDeleted: (state, action) => {
      state.barrelInboundDetails = state.barrelInboundDetails.filter((detail) => detail.unique !== action.payload);
    },
    barrelInboundEditSuccess: (state, action) => {
      const tempBarrelInbound = state.barrelInbounds.find((barrelInbound) => barrelInbound.id === action.payload);
      state.edit = true;
      state.barrelInbound = tempBarrelInbound;
      state.barrelInboundDetails = tempBarrelInbound?.barrelInboundDetails?.map((temp) => ({
        ...temp,
        unique: uuidv4(),
        capacity: capacityList?.find((capacity) => Number(capacity.id) === Number(temp.capacity)),
      }));
    },
    clearAllBarrelInbound: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingBarrelInbound = false;
      state.barrelInbound = null;
      state.barrelInboundDetails = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.barrelInbounds = [...state.barrelInbounds, ...action.payload.results];
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });

    builder.addCase(createBarrelInbound.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBarrelInbound.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createBarrelInbound.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateBarrelInbound.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateBarrelInbound.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateBarrelInbound.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addCase(getBarrelCodes.pending, (state) => {
      state.loadingBarrelCodes = true;
    });
    builder.addCase(getBarrelCodes.fulfilled, (state, action) => {
      state.loadingBarrelCodes = false;
      state.barrelCodes = action.payload?.results;
    });
    builder.addCase(getBarrelCodes.rejected, (state) => {
      state.loadingBarrelCodes = false;
    });
    builder.addMatcher(isAnyOf(getBarrelInbound.pending, handleSearch.pending), (state) => {
      state.loadingBarrelInbound = true;
    });
    builder.addMatcher(isAnyOf(getBarrelInbound.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingBarrelInbound = false;
      state.edit = false;
      state.barrelInbounds = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getBarrelInbound.rejected, handleSearch.rejected), (state) => {
      state.loadingBarrelInbound = false;
      state.edit = false;
    });
  },
});

export const {
  barrelInboundDetailsAdded,
  barrelInboundDetailsDeleted,
  barrelInboundDetailsUpdated,
  barrelInboundEditSuccess,
  clearAllBarrelInbound,
} = barrelInboundSlice.actions;

export default barrelInboundSlice.reducer;
