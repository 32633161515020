import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../Redux/Auth/authSlice";
import alertReducer from "../Redux/Alert/alertSlice";
import layoutReducer from "../Redux/Layout/layoutSlice";
import tabsValueReducer from "../Redux/TabsValue/tabsValueSlice";

import organizationReducer from "../Pages/Organization/Redux/organizationSlice";

import userGroupReducer from "../Pages/UserGroup/Redux/userGroupSlice";
import userReducer from "../Pages/User/Redux/userSlice";

import systemSelectionReducer from "../Redux/SystemSelection/systemSelectionSlice";

import notificationReducer from "../Components/Sidebar/redux/notificationSlice.js";

// item reducers
import itemReducer from "../Pages/Item/Redux/itemSlice";
import unitReducer from "../Pages/Unit/Redux/unitSlice";
import brandReducer from "../Pages/Brand/Redux/brandSlice.js";
import descriptionReducer from "../Pages/Description/Redux/descriptionSlice.js";

import supplierReducer from "../Pages/Supplier/Redux/supplierSlice";
import barrelInboundReducer from "../Pages/BarrelInbound/Redux/barrelInboundSlice.js";
import barrelFillReducer from "../Pages/BarrelFill/Redux/barrelFillSlice.js";
import barrelReducer from "../Pages/Barrel/Redux/barrelSlice.js";
// stock reducers
import stockAdditionReducer from "../Pages/StockAddition/Redux/stockAdditionSlice";
import stockSubtractionReducer from "../Pages/StockSubtraction/Redux/stockSubtractionSlice";
import stockAnalysisReducer from "../Pages/StockAnalysis/Redux/stockAnalysisSlice";
import stockAnalysisByBatchReducer from "../Pages/StockAnalysisByBatch/Redux/stockAnalysisByBatchSlice";
import stockAnalysisByLocationReducer from "../Pages/StockAnalysisByLocation/Redux/stockAnalysisByLocationSlice";
import itemLedgerReducer from "../Pages/ItemLedger/Redux/itemLedgerSlice";
import dashboardReducer from "../Pages/Dashboard/Redux/dashboardSlice";
import departmentReducer from "../Pages/Department/Redux/departmentSlice";
// location
import locationReducer from "../Pages/Location/Redux/locationSlice.js";
import itemInboundReducer from "../Pages/ItemInbound/Redux/itemInboundSlice";

// pick list
import pickListReducer from "../Pages/PickLists/Redux/pickListSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  layout: layoutReducer,
  tabsValue: tabsValueReducer,
  systemSelection: systemSelectionReducer,
  dashboard: dashboardReducer,
  department: departmentReducer,

  organization: organizationReducer,

  user: userReducer,
  userGroup: userGroupReducer,

  notification: notificationReducer,

  // item reducer
  item: itemReducer,
  brand: brandReducer,
  description: descriptionReducer,
  unit: unitReducer,
  supplier: supplierReducer,

  barrelInbound: barrelInboundReducer,
  itemInbound: itemInboundReducer,
  barrelFill: barrelFillReducer,
  barrel: barrelReducer,
  location: locationReducer,
  // stock addition reducer
  stockAnalysisByBatch: stockAnalysisByBatchReducer,
  stockAnalysisByLocation: stockAnalysisByLocationReducer,
  stockAddition: stockAdditionReducer,
  stockSubtraction: stockSubtractionReducer,
  stockAnalysis: stockAnalysisReducer,
  itemLedger: itemLedgerReducer,

  // pick list
  pickList: pickListReducer,
});

export default rootReducer;
