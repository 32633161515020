import axiosInstance from "../../Utils/axios";
import { publicAxiosInstance, axiosBranchInstance } from "../../Utils/axios";

//for login
export const  login = (body) =>
  publicAxiosInstance.post(`api/v1/user-app/login`, body);
//for logout
export const logout = (body) =>
  axiosInstance.post(`api/v1/user-app/logout`, body);

export const resetPassword = (body) =>
  publicAxiosInstance.post(`api/v1/user-app/password-reset/`, body);
export const confirmPassword = (body) =>
  publicAxiosInstance.post(`api/v1/user-app/reset-password/confirm/`, body);
export const changePassword = (id, body) =>
  axiosInstance.patch(`api/v1/user-app/change-password/${id}`, body);
export const getAllBranches = () => axiosBranchInstance.get(`api/v1/branches`);
export const checkSetup = () =>
  axiosInstance.get(`api/v1/core-app/setup-info`);