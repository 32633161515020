import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getStockAnalysisByBatch, getNext, handleSearch } from "./thunk";

const initialState = {
  batchStockAnalyses: [],
  count: null,
  next: null,
  previous: null,
  loadingNext: false,
  loadingStockAnalysisByBatch: false,
};

export const stockAnalysisByBatchSlice = createSlice({
  name: "stockAnalysisByBatch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.batchStockAnalyses = [...state.batchStockAnalyses, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addMatcher(isAnyOf(getStockAnalysisByBatch.pending, handleSearch.pending), (state) => {
      state.loadingStockAnalysisByBatch = true;
    });
    builder.addMatcher(isAnyOf(getStockAnalysisByBatch.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingStockAnalysisByBatch = false;
      state.batchStockAnalyses = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getStockAnalysisByBatch.rejected, handleSearch.rejected), (state) => {
      state.loadingStockAnalysisByBatch = false;
    });
  },
});

export const {} = stockAnalysisByBatchSlice.actions;

export default stockAnalysisByBatchSlice.reducer;
