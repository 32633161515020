import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get barrel
export const getBarrel = createAsyncThunk("barrel/getBarrel", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getBarrel(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get all barrel
export const getAllBarrel = createAsyncThunk("barrel/getAllBarrel", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllBarrel();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("barrel/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("barrel/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageBarrel = createAsyncThunk("barrel/getPageBarrel", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageBarrel(number, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create barrel
export const createBarrel = createAsyncThunk("barrel/createBarrel", async (data, { rejectWithValue }) => {
  const { name, code, rate, materialType, yarnQuality, description, active } = data;
  try {
    const body = JSON.stringify({
      name,
      code,
      rate,
      materialType,
      yarnQuality,

      description,
      active,
    });
    const { data } = await API.createBarrel(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const updateBarrel = createAsyncThunk("barrel/updateBarrel", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { name, code, rate, yarnType, materialType, barrelType, yarnQuality, description, active } = values;
  try {
    const body = JSON.stringify({
      name,
      code,
      rate,
      yarnType,
      materialType,
      yarnQuality,
      barrelType,
      description,
      active,
    });
    const { data } = await API.updateBarrel(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("barrel/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
